import React, { useRef, useMemo, useState, useContext } from "react";
import TextLoop from "react-text-loop";
import glamorous from "glamorous";
import axios from "axios";
import Lottie from 'react-lottie';
import * as dollarAnimationDesktop from '../animations/Dollars desktop.json';
import * as scoreAnimationDesktop from '../animations/Scoring Circles.json';
import Loading from "./Loading";
import Footer from "../layouts/Footer";
import { useCountUp } from "react-countup";
import { isReadByUser } from "../utilities/format";
import { RefContext } from "../contexts/RefContextProvider";
import { Link, useNavigate } from "react-router-dom";

const Container = () => {
  
  const navigate = useNavigate()

  const dollarAnimDesktop = {
    loop: true,
    autoplay: true,
    animationData: dollarAnimationDesktop,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const scoreAnimDesktop = {
    loop: false,
    autoplay: false,
    animationData: scoreAnimationDesktop,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const { homeRef, serviceRef, helpRef, aboutRef} = useContext(RefContext);

  const [sent, setSent] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [scrollY, setScrollY] = useState(0)

  const [beginPipeline, setBeginPipeline] = useState(false)

  const [beginScore, setBeginScore] = useState(false)

  const [fullName, setFullName] = useState("")

  const [email, setEmail] = useState("")

  const [company, setCompany] = useState("")

  const [phoneNumber, setPhoneNumber] = useState("")

  const [jobTitle, setJobTitle] = useState("")

  const [details, setDetails] = useState("")

  const pipelineRef = useRef(null);

  const scoreRef = useRef(null);

  const { update } = useCountUp({
    ref: pipelineRef,
    start: 0,
    delay: 0,
    duration: 3,
    separator: ",",
  });

  const CenteredText = glamorous.div({ textAlign: "center", width: "100vw", whiteSpace: "normal" });

  const submit = async () => {
    try {
      setSubmitting(true);
      const { data: { success } } = await axios.post("https://projectx-backend.vercel.app/", {
        fullName,
        email,
        company,
        jobTitle,
        phoneNumber,
        details
      })
      if(success) {
        setSent(true)
        setTimeout(() => {
          window.open("https://calendly.com/igniteintelligence/demo", "_blank") || (document.location.href = "https://calendly.com/igniteintelligence/demo")
          window.dataLayer.push({
            event: "form_complete",
          });
          navigate("/form-submitted");
        }, 2000)
      }
      else alert("Error occured.")
    } catch(e) {
      console.log(e)
    }
  }

  useMemo(() => beginPipeline && update(43900000), [beginPipeline, update])

  useMemo(() => {
    window.addEventListener("scroll", () => setScrollY(window.pageYOffset))
    return () => window.removeEventListener("scroll", () => setScrollY(window.pageYOffset))
  }, [])

  useMemo(() => {
    if(pipelineRef.current) setBeginPipeline(isReadByUser(scrollY, pipelineRef))
    if(scoreRef.current && !beginScore) setBeginScore(isReadByUser(scrollY, scoreRef))
  }, [scrollY, beginScore, pipelineRef, scoreRef])

  return (
    <div>
      <div className="ml-auto mr-auto max-w-[1000px] pt-[110px] lg:pt-[125px] px-[25px] lg:px-0">
        {/* Introduce */}
        <div className="flex flex-col items-center" ref={homeRef}>
          <span className="font-semibold text-[40px] lg:text-[70px] leading-[47.73px] lg:leading-[83.54px] tracking-[-0.05em] text-center text-white">We Generate <br className="md:hidden"/>BANT Leads for<br/>
            <div className="text-center overflow-hidden">
              <TextLoop interval={[1200, 1200, 1200, 1200, 5000]}>
                <CenteredText className="gradient-text bg-fire">the VOIP Industry</CenteredText>
                <CenteredText className="gradient-text bg-fire">Cloud Security</CenteredText>
                <CenteredText className="gradient-text bg-fire text-[40px] lg:text-[70px] loop-text">Unified Communication</CenteredText>
                <CenteredText className="gradient-text bg-fire text-[40px] lg:text-[70px] loop-text">Password Management</CenteredText>
                <CenteredText className="gradient-text bg-fire">IT Companies</CenteredText>
              </TextLoop>
            </div>
          </span>
          <span className="mt-5 font-semibold text-[14px] lg:text-[18px] leading-[20px] lg:leading-[25px] text-center text-grey1 max-w-[800px]">Ignite Intelligence is a B2B account based marketing (ABM) agency that focuses on generating high quality sales-ready leads for your IT business.</span>
          <Link to="/contact" className="mt-[30px] w-[325px] lg:w-[235px] h-[40px] p-[2px] rounded-[100px] bg-fire hover:bg-white demo-btn">
            <div className="flex justify-center items-center space-x-3 w-full h-full rounded-[100px] bg-black font-semibold text-[14px] lg:text-[18px] leading-[16.71px] lg:leading-[21.48px] tracking-[-0.05em]">
              <span>See a Demo</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                <path d="M14.5422 2.45872L14.5423 2.45875L18.8358 6.75095H1.75C1.48478 6.75095 1.23043 6.85631 1.04289 7.04384C0.855357 7.23138 0.75 7.48573 0.75 7.75095C0.75 8.01616 0.855357 8.27052 1.04289 8.45805C1.23043 8.64559 1.48478 8.75095 1.75 8.75095H18.8358L14.5423 13.0431L14.5422 13.0432C14.3545 13.2309 14.2491 13.4855 14.2491 13.7509C14.2491 14.0164 14.3545 14.271 14.5422 14.4587C14.7299 14.6464 14.9845 14.7519 15.25 14.7519C15.5155 14.7519 15.7701 14.6464 15.9578 14.4587L21.9576 8.45895C22.0507 8.36606 22.1246 8.2557 22.175 8.13421C22.2254 8.01272 22.2513 7.88248 22.2513 7.75095C22.2513 7.61941 22.2254 7.48917 22.175 7.36768C22.1246 7.24619 22.0507 7.13584 21.9576 7.04295L15.9578 1.04317C15.7701 0.855456 15.5155 0.75 15.25 0.75C14.9845 0.75 14.7299 0.855456 14.5422 1.04317C14.3545 1.23088 14.2491 1.48548 14.2491 1.75095C14.2491 2.01641 14.3545 2.27101 14.5422 2.45872Z" fill="white" stroke="white" strokeWidth="0.5"/>
              </svg>
            </div>
          </Link>
        </div>

        {/* Hero */}
        <div className="flex justify-center gap-5 flex-wrap mt-[226px] lg:mt-[554.62px] relative" ref={aboutRef}>
          <div className="absolute top-[-187px] lg:top-[-479.53px] w-full">
            <img className="m-auto w-[86.6666vw] lg:w-auto" src="images/MAC.png" alt="" />
            <div className="absolute bottom-[-1px] w-full h-[82.0914%] bg-macback" />
          </div>
          <div className="flex flex-col items-start w-[320px] min-h-[300px] lg:min-h-[364px] px-[35px] pt-[35px] bg-grey2 rounded-sm relative z-0">
            <div className="flex items-center min-h-[64px]">
              <img className="w-[71px]" src="images/hero/1.svg" alt="" />
            </div>
            <span className="mt-[18px] font-semibold text-[24px] leading-[28.64px] tracking-[-0.05em]">70% Higher Lead Conversion</span>
            <span className="mt-[15px] text-[14px] lg:text-[18px] leading-[150%] tracking-[-0.05em]">When our clients compared Ignite's lead close rates compared to their other lead sources.*</span>
          </div>
          <div className="flex flex-col items-start w-[320px] min-h-[300px] lg:min-h-[364px] px-[35px] pt-[35px] bg-grey2 rounded-sm relative z-0">
            <div className="flex items-center min-h-[64px]">
              <img className="w-[55.46px]" src="images/hero/2.svg" alt="" />
            </div>
            <span className="mt-[18px] font-semibold text-[24px] leading-[28.64px] tracking-[-0.05em]">Unique Lead Fingerprint</span>
            <span className="mt-[15px] text-[14px] lg:text-[18px] leading-[150%] tracking-[-0.05em]">Each lead is generated specifically for your business through our account based marketing (ABM) campaigns.</span>
          </div>
          <div className="flex flex-col items-start w-[320px] min-h-[300px] lg:min-h-[364px] px-[35px] pt-[35px] bg-grey2 rounded-sm relative z-0">
            <div className="flex items-center min-h-[64px]">
              <img className="w-[55px]" src="images/hero/3.svg" alt="" />
            </div>
            <span className="mt-[18px] font-semibold text-[24px] leading-[28.64px] tracking-[-0.05em]">100% Money Back Guarantee</span>
            <span className="mt-[15px] text-[14px] lg:text-[18px] leading-[150%] tracking-[-0.05em]">We are so confident in the quality of our leads that we are providing a money-back guarantee for all lead generation campaigns.</span>
          </div>
        </div>

        {/* Pipeline */}
        <div className="flex flex-col items-center mt-[100px] lg:mt-[200px]">
          <div className="flex flex-col items-center relative">
            <span className="font-semibold text-[30px] lg:text-[55px] leading-[35.8px] lg:leading-[65.63px] text-center">$<span ref={pipelineRef}></span>+</span>
            <span className="font-semibold text-[30px] lg:text-[55px] leading-[35.8px] lg:leading-[65.63px] text-center text-grey1">Pipeline Generated</span>
            <div className="hidden lg:block absolute bottom-[-5%] left-1/2 -translate-x-1/2 w-[898px] h-[324px]">
              <Lottie options={dollarAnimDesktop} isClickToPauseDisabled/>
            </div>
            <div className="block lg:hidden absolute bottom-[-5%] left-1/2 -translate-x-1/2 w-[150%]">
              <Lottie options={dollarAnimDesktop} isClickToPauseDisabled/>
            </div>
          </div>
          <span className="mt-[15px] font-semibold lg:font-normal text-[14px] lg:text-[18px] leading-[20px] lg:leading-[25px] text-center text-grey1 max-w-[800px]">
            At Ignite Intelligence, our goal is to achieve tangible outcomes and drive results. We focus on generating leads with an average closing timeframe of three months through various campaigns that involve engaging, nurturing, acquiring, and accelerating accounts.
          </span>
        </div>

        {/* Intent Scoring System */}
        <div className="flex flex-col items-center mt-[100px] lg:mt-[200px]">
          <span className="font-semibold text-[30px] lg:text-[55px] leading-[35.8px] lg:leading-[65.63px] gradient-text bg-fire text-center">Ignite Intelligence™</span>
          <span className="font-semibold text-[30px] lg:text-[55px] leading-[35.8px] lg:leading-[65.63px] gradient-text bg-white text-center">Intent Scoring System</span>
          <span className="mt-4 lg:mt-5 font-semibold lg:font-normal text-[14px] lg:text-[18px] leading-[20px] lg:leading-[25px] text-grey1 text-center max-w-unset lg:max-w-[800px]">Ignite Intelligence employs a unique intent scoring system that utilizes artificial intelligence to generate intent insight scores for the key accounts of its account-based marketing clients.</span>
          <div className="flex flex-col md:flex-row items-center mt-[35px] md:mt-[50px] space-y-[30px] md:space-x-[50px] md:space-y-0">
            <div className="w-[200px] h-[200px] lg:w-[307px] lg:h-[307px]" ref={scoreRef}>
              <Lottie options={scoreAnimDesktop} isClickToPauseDisabled isStopped={!beginScore}   />
            </div>
            <div className="flex flex-col space-y-[17.56px]">
              <div className="flex items-start space-x-[13px]">
                <img src="images/score/1.svg" alt="" />
                <div className="flex flex-col items-start space-y-[5.6px]">
                  <span className="text-[16px] leading-[25.36px] uppercase">
                    Implied Intent <span className="font-bold text-red1">93%</span><br/>
                    <span className="font-bold text-[24px] text-red1">93/100</span>
                  </span>
                  <span className="max-w-[367px] text-[14px] leading-[18.04px]">Explicit demand based on client pain, current state & innovation.</span>
                </div>
              </div>
              <div className="flex items-start space-x-[13px]">
                <img src="images/score/2.svg" alt="" />
                <div className="flex flex-col items-start space-y-[5.6px]">
                  <span className="text-[16px] leading-[25.36px] uppercase">
                    Verified Intent <span className="font-bold text-pink1">95%</span><br/>
                    <span className="font-bold text-[24px] text-pink1">95/100</span>
                  </span>
                  <span className="max-w-[367px] text-[14px] leading-[18.04px]">Received from web pixel data, topic data & other intent signals.</span>
                </div>
              </div>
              <div className="flex items-start space-x-[13px]">
                <img src="images/score/3.svg" alt="" />
                <div className="flex flex-col items-start space-y-[5.6px]">
                  <span className="text-[16px] leading-[25.36px] uppercase">
                    1st Party Engagement <span className="font-bold text-red1">99%</span><br/>
                    <span className="font-bold text-[24px] text-red1">99/100</span>
                  </span>
                  <span className="max-w-[367px] text-[14px] leading-[18.04px]">Campaign engagement & CRM automation signals.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Go-to-Market with More Than Just Data */}
      <div className="mt-[100px] lg:mt-[200px] relative">
        <img className="hidden 2lg:block w-full" src="images/man-back.png" alt="" />
        <img className="block 2lg:hidden w-full h-[819px]" src="images/man-back-mobile.png" alt="" />
        <div className="flex justify-center block 2lg:hidden absolute bottom-[-1px] w-full man-container">
          <img className="w-[233px]" src="images/man-mobile.png" alt="" />
        </div>
        <div className="flex justify-end items-center absolute top-[118.5px] 2lg:top-1/2 left-1/2 -translate-x-1/2 2lg:-translate-y-1/2 ml-auto mr-auto 2lg:w-[1000px]">
          <div className="flex flex-col items-center 2lg:items-start space-y-5">
            <span className="font-semibold text-[40px] lg:text-[50px] leading-[44.13px] lg:leading-[55.17px] tracking-[-0.05em] max-w-[500px] text-center 2lg:text-left">Go-to-Market with More Than Just Data</span>
            <span className="text-[14px] lg:text-[18px] leading-[20px] lg:leading-[25px] max-w-[452px] text-grey1 text-center 2lg:text-left">GTM teams need dynamic B2B buyer intelligence, activation tools, and demand programs to carry out their continuous GTM strategies.</span>
            <Link to="/contact" className="mt-[25px] lg:mt-[53px] w-[325px] lg:w-[235px] h-[40px] p-[2px] rounded-[100px] bg-fire hover:bg-white demo-btn">
              <div className="flex justify-center items-center space-x-3 w-full h-full rounded-[100px] bg-grey2 font-semibold text-[14px] lg:text-[18px] leading-[16.71px] lg:leading-[21.48px] tracking-[-0.05em]">
                <span>See a Demo</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                  <path d="M14.5422 2.45872L14.5423 2.45875L18.8358 6.75095H1.75C1.48478 6.75095 1.23043 6.85631 1.04289 7.04384C0.855357 7.23138 0.75 7.48573 0.75 7.75095C0.75 8.01616 0.855357 8.27052 1.04289 8.45805C1.23043 8.64559 1.48478 8.75095 1.75 8.75095H18.8358L14.5423 13.0431L14.5422 13.0432C14.3545 13.2309 14.2491 13.4855 14.2491 13.7509C14.2491 14.0164 14.3545 14.271 14.5422 14.4587C14.7299 14.6464 14.9845 14.7519 15.25 14.7519C15.5155 14.7519 15.7701 14.6464 15.9578 14.4587L21.9576 8.45895C22.0507 8.36606 22.1246 8.2557 22.175 8.13421C22.2254 8.01272 22.2513 7.88248 22.2513 7.75095C22.2513 7.61941 22.2254 7.48917 22.175 7.36768C22.1246 7.24619 22.0507 7.13584 21.9576 7.04295L15.9578 1.04317C15.7701 0.855456 15.5155 0.75 15.25 0.75C14.9845 0.75 14.7299 0.855456 14.5422 1.04317C14.3545 1.23088 14.2491 1.48548 14.2491 1.75095C14.2491 2.01641 14.3545 2.27101 14.5422 2.45872Z" fill="white" stroke="white" strokeWidth="0.5"/>
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="ml-auto mr-auto max-w-[1000px] pb-5 lg:pb-[26px] px-[25px] lg:px-0 mt-6 lg:mt-[75.5px]">
        {/* Steps */}
        <div className="flex flex-col items-center">
          <div className="hidden tiny:flex justify-center space-x-[50px]">
            <div className="flex flex-col items-center">
              <span className="font-semibold text-[18px] leading-[21.48px] tracking-[-0.05em] text-grey1 text-center">Step 1</span>
              <img className="mt-[23.56px] w-[100px]" src="images/get-started/1.svg" alt="" />
              <span className="mt-[21.94px] font-semibold text-[24px] leading-[25px] max-w-[200px] text-center">Schedule Demo with Our Team</span>
            </div>
            <div className="flex flex-col items-center">
              <span className="font-semibold text-[18px] leading-[21.48px] tracking-[-0.05em] text-grey1 text-center">Step 2</span>
              <img className="mt-[13.87px] w-[100px]" src="images/get-started/2.svg" alt="" />
              <span className="mt-[14.22px] font-semibold text-[24px] leading-[25px] max-w-[200px] text-center">We kickoff your ABM campaign</span>
            </div>
            <div className="flex flex-col items-center">
              <span className="font-semibold text-[18px] leading-[21.48px] tracking-[-0.05em] text-grey1 text-center">Step 3</span>
              <img className="mt-[21.29px] w-[100px]" src="images/get-started/3.svg" alt="" />
              <span className="mt-[21.66px] font-semibold text-[24px] leading-[25px] max-w-[200px] text-center">Lead handoff to sales team</span>
            </div>
          </div>
          <div className="flex tiny:hidden flex-col space-y-[25px]">
            <div className="flex items-center space-x-[21px]">
              <img className="w-[51px] h-[51px]" src="images/get-started/1.svg" alt="" />
              <div className="flex flex-col">
                <span className="font-semibold text-[12px] leading-[25px] text-grey1">Step 1</span>
                <span className="font-semibold text-[20px] leading-[25px]">Schedule Demo with Our Team</span>
              </div>
            </div>
            <div className="flex items-center space-x-[21px]">
              <img className="w-[51px] h-[51px]" src="images/get-started/2.svg" alt="" />
              <div className="flex flex-col">
                <span className="font-semibold text-[12px] leading-[25px] text-grey1">Step 2</span>
                <span className="font-semibold text-[20px] leading-[25px]">We kickoff your ABM campaign</span>
              </div>
            </div>
            <div className="flex items-center space-x-[21px]">
              <img className="w-[51px] h-[51px]" src="images/get-started/3.svg" alt="" />
              <div className="flex flex-col">
                <span className="font-semibold text-[12px] leading-[25px] text-grey1">Step 3</span>
                <span className="font-semibold text-[20px] leading-[25px]">Lead handoff to sales team</span>
              </div>
            </div>
          </div>
        </div>

        {/* Key Advantages */}
        <div className="flex justify-center lg:justify-between items-center mt-[100px] lg:mt-[194px]" ref={serviceRef}>
          <div className="flex flex-col items-start max-w-[464.93px]">
            <span className="block lg:hidden font-semibold text-[40px] leading-[47.73px] tracking-[-0.05em] text-center">Ignite Intelligence Key Advantages</span>
            <span className="hidden lg:block font-semibold text-[35px] leading-[41.77px] tracking-[-0.05em]">Ignite Intelligence Key Advantages</span>
            <span className="mt-5 lg:mt-[10px] text-[14px] lg:text-[18px] leading-[20px] lg:leading-[25px] text-grey1 text-center lg:text-left">We have established a comprehensive ABM insight and intelligence system to ensure that we acquire the decision making individuals in charge of budget, needs & timeline.</span>
            <div className="mt-[282px] lg:mt-[14px] grid grid-cols-2 gap-x-[9px] lg:gap-x-[16.81px] gap-y-[10px] lg:gap-y-[15px] relative">
              <div className="block lg:hidden absolute top-[-227px] left-1/2 -translate-x-1/2 w-full">
                <img className="w-[325px] mx-auto" src="images/insight.png" alt="" />
                <div className="absolute top-[40%] w-full h-[82.0914%] bg-diffback" />
              </div>
              <div className="flex flex-col items-start pt-[17.09px] lg:pt-[22.09px] pb-[17px] lg:pb-[23px] pl-[14px] lg:pl-[19.53px] pr-[15px] lg:pr-[19.88px] bg-grey2 rounded-sm relative">
                <img className="w-[42px] h-[42px]" src="images/difference/1.svg" alt="" />
                <span className="mt-2 lg:mt-[15.36px] font-semibold text-[14px] lg:text-[18px] leading-[18px] lg:leading-[25px]">Lead Generation</span>
                <span className="mt-2.5 lg:mt-[5.23px] font-light text-[10px] lg:text-[12px] leading-[18px]">We generate B2B leads using e-books, case studies & our call center of 25 in-house agents.</span>
              </div>
              <div className="flex flex-col items-start pt-[17.09px] lg:pt-[22.09px] pb-[17px] lg:pb-[23px] pl-[14px] lg:pl-[19.53px] pr-[15px] lg:pr-[19.88px] bg-grey2 rounded-sm relative">
                <img className="w-[42px] h-[42px]" src="images/difference/2.svg" alt="" />
                <span className="mt-2 lg:mt-[15.36px] font-semibold text-[14px] lg:text-[18px] leading-[18px] lg:leading-[25px]">Quality Control</span>
                <span className="mt-2.5 lg:mt-[5.23px] font-light text-[10px] lg:text-[12px] leading-[18px]">Each lead is inspected and verified by our quality control team.</span>
              </div>
              <div className="flex flex-col items-start pt-[17.09px] lg:pt-[22.09px] pb-[17px] lg:pb-[23px] pl-[14px] lg:pl-[19.53px] pr-[15px] lg:pr-[19.88px] bg-grey2 rounded-sm relative">
                <img className="w-[42px] h-[42px]" src="images/difference/3.svg" alt="" />
                <span className="mt-2 lg:mt-[15.36px] font-semibold text-[14px] lg:text-[18px] leading-[18px] lg:leading-[25px]">Account Based Marketing</span>
                <span className="mt-2.5 lg:mt-[5.23px] font-light text-[10px] lg:text-[12px] leading-[18px]">Our ABM is a strategic approach that focuses on targeting key accounts with highly personalized campaigns.</span>
              </div>
              <div className="flex flex-col items-start pt-[17.09px] lg:pt-[22.09px] pb-[17px] lg:pb-[23px] pl-[14px] lg:pl-[19.53px] pr-[15px] lg:pr-[19.88px] bg-grey2 rounded-sm relative">
                <img className="w-[42px] h-[42px]" src="images/difference/4.svg" alt="" />
                <span className="mt-2 lg:mt-[15.36px] font-semibold text-[14px] lg:text-[18px] leading-[18px] lg:leading-[25px]">Shortened Sales Cycles</span>
                <span className="mt-2.5 lg:mt-[5.23px] font-light text-[10px] lg:text-[12px] leading-[18px]">We shorten average sales cycles by providing clients with key decision makers.</span>
              </div>
            </div>
          </div>
          <img className="hidden lg:block w-[500px]" src="images/insight.png" alt="" />
        </div>

        {/* Miami city */}
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center mt-[100px] lg:mt-[200px] relative">
          <img className="mt-5 lg:mt-0 w-[453px]" src="images/miami.png" alt="" />
          <div className="flex flex-col items-center max-w-[501px] gap-y-4">
            <span className="font-semibold text-[40px] lg:text-[70px] leading-[48px] lg:leading-[77.24px] tracking-[-0.05em] text-center lg:text-left">Based in Sunny Miami Florida</span>
            <div className="flex items-start space-x-1 lg:space-x-2">
              <img className="w-[15px] h-[18px] mt-0.5 lg:w-auto lg:h-auto lg:mt-0" src="images/map-grey.svg" alt="" />
              <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px] tracking-[-0.05em] lg:tracking-auto text-grey1 text-center lg:text-left">The Ignite Intelligence HQ is located at 2980 NE 207th St Suite 336, Aventura, FL 33180</span>
            </div>
          </div>
        </div>

        {/* Get in touch */}
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-[50px] lg:space-y-0 mt-[100px] lg:mt-[200px]" ref={helpRef}>
          <div className="flex flex-col items-start">
            <span className="font-semibold text-[40px] lg:text-[70px] leading-[44.13px] lg:leading-[77.24px] tracking-[-0.05em] lg:tracking-auto max-w-[445px]">Generate High Quality BANT Leads for Your IT Business</span>
            <span className="font-semibold text-[18px] lg:text-[20px] leading-[25px] text-grey1 mt-5 lg:mt-8">We specialize in the following IT fields:</span>
            <div className="flex flex-col items-start space-y-[10px] lg:space-y-[15px] mt-5">
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">VOIP</span>
              </div>
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">Unified Communications</span>
              </div>
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">Cloud Security</span>
              </div>
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">Password Management</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-[500px] overflow-hidden">
            {!sent ?
              <>
                <div className="flex justify-center items-center bg-grey2 rounded-tl-[15px] rounded-tr-[15px] md:py-[10px]">
                  <span className="gradient-text bg-fire font-semibold text-[30px] lg:text-[45px] leading-[52.5px]">Get in Touch</span>
                </div>
                <div className="border-x-2 border-b-2 border-grey2 rounded-bl-[15px] rounded-br-[15px] pt-5 pb-5 md:pb-[30px] px-[18px] md:px-7">
                  <div className="flex flex-col space-y-4 w-full lg:w-auto">
                    <div className="flex space-x-4">
                      <div className="flex flex-col flex-1">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Full Name*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px] overflow-hidden">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              placeholder="Enter your name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-1">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Email*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px] overflow-hidden">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="flex flex-col">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Company Name*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px] overflow-hidden">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              placeholder="Enter your company"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Job Title*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px] overflow-hidden">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={jobTitle}
                              onChange={(e) => setJobTitle(e.target.value)}
                              placeholder="Enter your job title"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Phone Number*</span>
                      <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                        <div className="w-full h-full bg-grey2 rounded-[5px] overflow-hidden">
                          <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter your contact number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Anything else we should know?</span>
                      <div className="mt-[5px] w-full p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                        <div className="w-full bg-grey2 rounded-[5px] overflow-hidden">
                          <textarea className="w-full h-[80px] text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            placeholder="Enter any additional details"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="flex justify-center items-center w-full h-[45px] text-white font-semibold text-[14px] md:text-[18px] leading-[25px] bg-fire disabled:bg-grey7 mt-4 rounded-[5px]"
                    onClick={submit}
                    disabled={submitting || fullName === "" || email === "" || company === "" || jobTitle === "" || phoneNumber === ""}
                  >
                    Submit
                  </button>
                </div>
              </>
            :
              <div className="flex flex-col items-center space-y-[35px] border-2 border-grey2 rounded-[15px] pt-[193px] pb-[188px]">
                <img className="w-[100px] md:w-[114px]" src="images/success.svg" alt="" />
                <span className="font-semibold text-[22px] md:text-[24px] leading-[28.64px] text-center md:w-[291px]">Now let's schedule your demo on Calendly.</span>
              </div>
            }
          </div>
        </div>

        {/* Strengthen */}
        <div className="flex flex-col items-center lg:items-start h-[597px] lg:h-auto pt-10 lg:pt-[37px] pb-9 px-7 lg:px-[50px] bg-grey2 rounded-[20px] relative overflow-hidden mt-[100px]">
          <div className="hidden lg:block w-[444px] h-[444px] absolute top-[-60px] right-[-73px] rounded-full bg-fire" />
          <div className="block lg:hidden w-[444px] h-[444px] absolute bottom-[-177px] left-1/2 -translate-x-1/2 rounded-full bg-fire" />
          <img className="hidden lg:block absolute bottom-0 right-0 w-[450px]" src="images/MacBook Air M2.png" alt="" />
          <img className="block lg:hidden absolute bottom-[-38px] left-[42%] md:left-[45%] -translate-x-1/2 min-w-[521.58px] h-[248px]" src="images/MacBook Air M2-mobile.png" alt="" />
          <span className="font-semibold text-[35px] leading-[100%] gradient-text bg-fire text-center relative z-1">Strengthen Your Sales Pipeline</span>
          <span className="mt-3 text-[14px] lg:text-[18px] leading-[25px] max-w-[490px] text-grey1 text-center lg:text-left relative z-1">Learn more about Ignite's unique approach to ABM & lead generation.</span>
          <Link to="/contact" className="mt-5 lg:mt-[10px] w-[235px] h-[40px] p-[2px] rounded-[100px] bg-fire hover:bg-white demo-btn relative z-1">
            <div className="flex justify-center items-center space-x-3 w-full h-full rounded-[100px] bg-grey2 font-semibold text-[14px] lg:text-[18px] leading-[16.71px] lg:leading-[21.48px] tracking-[-0.05em]">
              <span>See a Demo</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                <path d="M14.5422 2.45872L14.5423 2.45875L18.8358 6.75095H1.75C1.48478 6.75095 1.23043 6.85631 1.04289 7.04384C0.855357 7.23138 0.75 7.48573 0.75 7.75095C0.75 8.01616 0.855357 8.27052 1.04289 8.45805C1.23043 8.64559 1.48478 8.75095 1.75 8.75095H18.8358L14.5423 13.0431L14.5422 13.0432C14.3545 13.2309 14.2491 13.4855 14.2491 13.7509C14.2491 14.0164 14.3545 14.271 14.5422 14.4587C14.7299 14.6464 14.9845 14.7519 15.25 14.7519C15.5155 14.7519 15.7701 14.6464 15.9578 14.4587L21.9576 8.45895C22.0507 8.36606 22.1246 8.2557 22.175 8.13421C22.2254 8.01272 22.2513 7.88248 22.2513 7.75095C22.2513 7.61941 22.2254 7.48917 22.175 7.36768C22.1246 7.24619 22.0507 7.13584 21.9576 7.04295L15.9578 1.04317C15.7701 0.855456 15.5155 0.75 15.25 0.75C14.9845 0.75 14.7299 0.855456 14.5422 1.04317C14.3545 1.23088 14.2491 1.48548 14.2491 1.75095C14.2491 2.01641 14.3545 2.27101 14.5422 2.45872Z" fill="white" stroke="white" strokeWidth="0.5"/>
              </svg>
            </div>
          </Link>
        </div>
        <div className="mt-[100px] md:mt-[108px]">
          <Footer />
        </div>
      </div>
    </div>
  )
}

const Landing = () => {

  const [loading, setLoading] = useState(false)
  
  return (
    <div className="overflow-hidden">
      {!loading ?
        <Loading setLoading={setLoading} /> 
      :
        <Container />
      }
    </div>
  );
};

export default Landing;
