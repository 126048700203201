import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";

const Contact = () => {
  
  const navigate = useNavigate()

  const [sent, setSent] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [fullName, setFullName] = useState("")

  const [email, setEmail] = useState("")

  const [company, setCompany] = useState("")

  const [phoneNumber, setPhoneNumber] = useState("")

  const [jobTitle, setJobTitle] = useState("")

  const [details, setDetails] = useState("")

  const submit = async () => {
    try {
      setSubmitting(true);
      const { data: { success } } = await axios.post("https://projectx-backend.vercel.app/", {
        fullName,
        email,
        company,
        jobTitle,
        phoneNumber,
        details
      })
      if(success) {
        setSent(true)
        setTimeout(() => {
          window.open("https://calendly.com/igniteintelligence/demo", "_blank") || (document.location.href = "https://calendly.com/igniteintelligence/demo")
          window.dataLayer.push({
            event: "form_complete",
          });
          navigate("/form-submitted");
        }, 2000)
      }
      else alert("Error occured.")
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <div className="overflow-hidden">
      <div className="ml-auto mr-auto max-w-[1000px] pb-[26px] lg:pb-20 px-[25px] lg:px-0">
        {/* Get in touch */}
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-[50px] lg:space-y-0 mt-[110px] lg:mt-[120px]">
          <div className="flex flex-col items-start">
            <span className="font-semibold text-[40px] lg:text-[70px] leading-[44.13px] lg:leading-[77.24px] tracking-[-0.05em] lg:tracking-auto max-w-[445px]">Generate High Quality BANT Leads for Your IT Business</span>
            <span className="font-semibold text-[18px] lg:text-[20px] leading-[25px] text-grey1 mt-5 lg:mt-8">We specialize in the following IT fields:</span>
            <div className="flex flex-col items-start space-y-[10px] lg:space-y-[15px] mt-5">
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">VOIP</span>
              </div>
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">Unified Communications</span>
              </div>
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">Cloud Security</span>
              </div>
              <div className="flex items-center space-x-[10px]">
                <div className="w-[10px] h-[10px] bg-fire rounded-full" />
                <span className="text-[18px] lg:text-[20px] leading-[25px] text-grey1">Password Management</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-[500px] overflow-hidden">
            {!sent ?
              <>
                <div className="flex justify-center items-center bg-grey2 rounded-tl-[15px] rounded-tr-[15px] md:py-[10px]">
                  <span className="hidden md:block gradient-text bg-fire font-semibold text-[30px] lg:text-[35px] leading-[52.5px]">Schedule a Demo</span>
                  <span className="block md:hidden gradient-text bg-fire font-semibold text-[30px] lg:text-[35px] leading-[52.5px]">Get in Touch</span>
                </div>
                <div className="border-x-2 border-b-2 border-grey2 rounded-bl-[15px] rounded-br-[15px] pt-5 pb-5 md:pb-[30px] px-[18px] md:px-7">
                  <div className="flex flex-col space-y-4 w-full lg:w-auto">
                    <div className="flex space-x-4">
                      <div className="flex flex-col flex-1">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Full Name*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px]">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              placeholder="Enter your name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-1">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Email*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px]">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="flex flex-col">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Company Name*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px]">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              placeholder="Enter your company"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Job Title*</span>
                        <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                          <div className="w-full h-full bg-grey2 rounded-[5px]">
                            <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                              value={jobTitle}
                              onChange={(e) => setJobTitle(e.target.value)}
                              placeholder="Enter your job title"
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Phone Number*</span>
                      <div className="mt-[5px] w-full h-[45px] p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                        <div className="w-full h-full bg-grey2 rounded-[5px]">
                          <input className="w-full h-full text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter your contact number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-[14px] lg:text-[18px] leading-[25px]">Anything else we should know?</span>
                      <div className="mt-[5px] w-full p-[2px] rounded-[5px] bg-grey6 focus:bg-fire focus-within:bg-fire" tabIndex={-1}>
                        <div className="w-full bg-grey2 rounded-[5px]">
                          <textarea className="w-full h-[80px] text-[12px] lg:text-[14px] leading-[25px] placeholder-grey7 px-[10px] md:px-4 py-2"
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            placeholder="Enter any additional details"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="flex justify-center items-center w-full h-[45px] text-white font-semibold text-[14px] md:text-[18px] leading-[25px] bg-fire disabled:bg-grey7 mt-4 rounded-[5px]"
                    onClick={submit}
                    disabled={submitting || fullName === "" || email === "" || company === "" || jobTitle === "" || phoneNumber === ""}
                  >
                    Submit
                  </button>
                </div>
              </>
            :
              <div className="flex flex-col items-center space-y-[35px] border-2 border-grey2 rounded-[15px] pt-[193px] pb-[188px]">
                <img className="w-[100px] md:w-[114px]" src="images/success.svg" alt="" />
                <span className="font-semibold text-[22px] md:text-[24px] leading-[28.64px] text-center md:w-[291px]">Now let's schedule your demo on Calendly.</span>
              </div>
            }
          </div>
        </div>

        {/* Strengthen */}
        <div className="flex flex-col items-center lg:items-start h-[597px] lg:h-auto pt-10 lg:pt-[37px] pb-9 px-7 lg:px-[50px] bg-grey2 rounded-[20px] relative overflow-hidden mt-[100px] lg:mt-[148px]">
          <div className="hidden lg:block w-[444px] h-[444px] absolute top-[-60px] right-[-73px] rounded-full bg-fire" />
          <div className="block lg:hidden w-[444px] h-[444px] absolute bottom-[-177px] left-1/2 -translate-x-1/2 rounded-full bg-fire" />
          <img className="hidden lg:block absolute bottom-0 right-0 w-[500px]" src="images/MacBook Air M2.png" alt="" />
          <img className="block lg:hidden absolute bottom-[-38px] left-[42%] md:left-[45%] -translate-x-1/2 min-w-[521.58px] h-[248px]" src="images/MacBook Air M2-mobile.png" alt="" />
          <span className="font-semibold text-[35px] leading-[100%] gradient-text bg-fire text-center relative z-1">Strengthen Your Sales Pipeline</span>
          <span className="mt-3 text-[14px] lg:text-[18px] leading-[25px] max-w-[490px] text-grey1 text-center lg:text-left relative z-1">Learn more about Ignite's unique approach to ABM & lead generation.</span>
          <button className="mt-5 lg:mt-[10px] w-[235px] h-[40px] p-[2px] rounded-[100px] bg-fire hover:bg-white relative z-1">
            <div className="flex justify-center items-center space-x-3 w-full h-full rounded-[100px] bg-grey2 font-semibold text-[14px] lg:text-[18px] leading-[16.71px] lg:leading-[21.48px] tracking-[-0.05em]">
              <span>See a Demo</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none">
                <path d="M14.5422 2.45872L14.5423 2.45875L18.8358 6.75095H1.75C1.48478 6.75095 1.23043 6.85631 1.04289 7.04384C0.855357 7.23138 0.75 7.48573 0.75 7.75095C0.75 8.01616 0.855357 8.27052 1.04289 8.45805C1.23043 8.64559 1.48478 8.75095 1.75 8.75095H18.8358L14.5423 13.0431L14.5422 13.0432C14.3545 13.2309 14.2491 13.4855 14.2491 13.7509C14.2491 14.0164 14.3545 14.271 14.5422 14.4587C14.7299 14.6464 14.9845 14.7519 15.25 14.7519C15.5155 14.7519 15.7701 14.6464 15.9578 14.4587L21.9576 8.45895C22.0507 8.36606 22.1246 8.2557 22.175 8.13421C22.2254 8.01272 22.2513 7.88248 22.2513 7.75095C22.2513 7.61941 22.2254 7.48917 22.175 7.36768C22.1246 7.24619 22.0507 7.13584 21.9576 7.04295L15.9578 1.04317C15.7701 0.855456 15.5155 0.75 15.25 0.75C14.9845 0.75 14.7299 0.855456 14.5422 1.04317C14.3545 1.23088 14.2491 1.48548 14.2491 1.75095C14.2491 2.01641 14.3545 2.27101 14.5422 2.45872Z" fill="white" stroke="white" strokeWidth="0.5"/>
              </svg>
            </div>
          </button>
        </div>
        <div className="mt-[100px] md:mt-[108px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Contact;
