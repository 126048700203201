import Layout from "./layouts";
import Landing from "./pages/Landing";
import Contact from "./pages/Contact";
import FormSubmitted from "./pages/FormSubmitted";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/form-submitted" element={<FormSubmitted />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;