import React, { useMemo } from "react";
import Lottie from 'react-lottie';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import * as loadingAnimation from '../animations/Loading.json';

const Loading = ({ setLoading }) => {

  const loadingAnim = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useMemo(() => {
    disableBodyScroll(document.body)
    setTimeout(() => {
      setLoading(true)
      enableBodyScroll(document.body)
    }, 3000)
  }, [setLoading])

  return (
    <div className="fixed top-0 left-0 w-full h-full z-[100]">
      <img className="absolute w-full h-full object-cover" src="images/loading-back.png" alt="" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-blackblur-linear md:bg-blackblur-radial" />
      <div className="flex flex-col items-center space-y-[23px] w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="w-[192px] h-[192px] md:w-[136px] md:h-[136px] relative">
          <Lottie options={loadingAnim} isClickToPauseDisabled />
          <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[67.2748%]" src="images/logo.svg" alt="" />
        </div>
        <div className="mx-[56px] font-semibold text-[24px] md:text-[18px] leading-[26.48px] leading-[110.3333%] tracking-[-0.05em] text-white text-center">Ignite your pipeline with Ignite Intelligence ABM.</div>
      </div>
    </div>
  );
};

export default Loading;