import React, { useContext } from "react";
import { RefContext } from "../contexts/RefContextProvider";

const Footer = () => {
  const { footerRef } = useContext(RefContext);
  return (
    <footer className="max-w-[1000px] ml-auto mr-auto" ref={footerRef}>
      <div className="flex justify-between gap-x-5 gap-y-[17px] footergrid">
        <div className="flex flex-col items-start space-y-[11px] md:space-y-5">
          <div className="flex items-center space-x-[8.4px] text-[24px] leading-[28.64px] font-semibold">
            <img className="w-[122.82px] h-[71.65px]" src="images/logo.svg" alt="" />
          </div>
          <span className="text-[14px] leading-[22px] md:leading-[18px] tracking-[0.2px] md:max-w-[168px]">Ignite your go-to-market strategy.</span>
        </div>
        <div className="flex flex-col space-y-[9px] md:space-y-[29px]">
          <div className="text-[18px] leading-[22px] md:text-[24px] md:leading-[22px] text-grey1 font-semibold">Company HQ</div>
          <div className="flex items-start space-x-[11px]">
            <img src="images/map.svg" alt="" />
            <span className="text-[14px] tracking-[0.2px]">2980 NE 207th St Suite 336,<br/>Aventura, FL 33180</span>
          </div>
        </div>
        <div className="flex flex-col space-y-[9px] md:space-y-[29px]">
          <div className="text-[18px] leading-[22px] md:text-[24px] md:leading-[22px] text-grey1 font-semibold">Contact Us</div>
          <div className="flex flex-col space-y-[10px] text-[13px] leading-[15.51px] md:text-[14px] md:leading-[16.71px]">
            <div className="flex items-center space-x-2">
              <img src="images/envelop.svg" alt="" />
              <span>business@igniteintelligence.io</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="images/phone-call.svg" alt="" />
              <span>+1 833-398-1222</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-[9px] md:space-y-[29px]">
          <div className="hidden md:block text-[18px] leading-[22px] md:text-[24px] md:leading-[22px] text-grey1 font-semibold">Social Media</div>
          <div className="flex justify-center tiny:justify-start items-center space-x-[33px] tiny:space-x-[18px]">
            <a href="https://igniteintelligence.io" target="_blank" rel="noreferrer"><img className="cursor-pointer" src="images/social/mail.svg" alt="" /></a>
            <a href="https://igniteintelligence.io" target="_blank" rel="noreferrer"><img className="cursor-pointer" src="images/social/linkedin.svg" alt="" /></a>
            <a href="https://igniteintelligence.io" target="_blank" rel="noreferrer"><img className="cursor-pointer" src="images/social/website.svg" alt="" /></a>
          </div>
        </div>
      </div>
      <div className="mt-[20px] md:mt-[34px] w-full h-[1px] bg-fire" />
      <div className="mt-[22px] md:mt-5 text-[12px] tiny:text-[14px] leading-[14.32px] tiny:leading-[22px] text-center font-medium">@ 2023 Igniteintelligence.io{"\u00a0\u00a0\u00a0"}All rights reserved</div>
    </footer>
  );
};

export default Footer;
