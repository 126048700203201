import React, { useContext } from "react";
import { Fade } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RefContext } from "../contexts/RefContextProvider";

const Topbar = ({ openMenu, setOpenMenu }) => {
  const nav = useNavigate()

  const loc = useLocation()

  const { headerRef, homeRef, aboutRef, serviceRef, helpRef } = useContext(RefContext);

  const navigate = async (ref) => {
    nav("/")
    if (loc.pathname === "/") {
      var headerOffset = 150;
      var elementPosition = ref.current.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
      setOpenMenu(false);
    }
  }

  return (
    <header className="fixed lg:absolute w-full z-[6] bg-[rgba(0,0,0,0.7)] backdrop-blur-sm" ref={headerRef}>
      <div className="flex justify-between items-center max-w-[1000px] ml-auto mr-auto px-[25px] lg:px-0 py-[30px] lg:py-[22px] relative">
        <Link to="/">
          <div className="flex items-center logo">
            <img className="w-[59.5px] h-[35px] mobile:w-auto mobile:h-auto" src="images/logo.svg" alt="" />
          </div>
        </Link>        
        <div className="flex flex-col items-end gap-y-1 md:hidden cursor-pointer relative" onClick={() => setOpenMenu(!openMenu)}>
          <div className={`absolute bg-fire w-[30px] h-[5px] rounded-[2.5px] transition-all origin-top-right ${openMenu && "-rotate-45 translate-x-[-12px]"}`} />
          <div className={`bg-fire w-[30px] h-[5px] rounded-[2.5px] transition-all origin-top-left ${openMenu && "rotate-45"}`} />
          <div className={`bg-fire w-[24px] h-[5px] rounded-[2.5px] transition-all ${openMenu && "opacity-0"}`} />
          <div className={`bg-fire w-[16px] h-[5px] rounded-[2.5px] transition-all ${openMenu && "opacity-0"}`} />
        </div>
        <Fade in={openMenu}>
          <div className="flex md:hidden flex-col items-center space-y-[30px] pt-[14px] pb-[45px] absolute top-full left-0 w-screen bg-[rgba(0,0,0,0.9)]">
            <span className="cursor-pointer text-[18px] leading-[21.48px] tracking-[0.2px] hover:text-blue1 transition-all" onClick={() => navigate(homeRef)}>Home</span>
            <span className="cursor-pointer text-[18px] leading-[21.48px] tracking-[0.2px] hover:text-blue1 transition-all" onClick={() => navigate(aboutRef)}>About</span>
            <span className="cursor-pointer text-[18px] leading-[21.48px] tracking-[0.2px] hover:text-blue1 transition-all" onClick={() => navigate(serviceRef)}>Service</span>
            <span className="cursor-pointer text-[18px] leading-[21.48px] tracking-[0.2px] hover:text-blue1 transition-all" onClick={() => navigate(helpRef)}>Help</span>
            <Link to="/contact" className="mt-[2px] w-[150px] h-[40px] p-[2px] rounded-[106px] bg-fire hover:bg-white">
              <div className="flex justify-center items-center w-full h-full rounded-[106px] bg-black font-semibold text-[18px] leading-[21.48px] tracking-[-0.05em]">
                Contact Us
              </div>
            </Link>
          </div>
        </Fade>
        <Link to="/contact" className="hidden md:block w-[110px] h-[30px] p-[2px] rounded-[106px] bg-fire hover:bg-white">
          <div className="flex justify-center items-center w-full h-full rounded-[106px] bg-black font-semibold text-[14px] leading-[16.71px] tracking-[-0.05em]">
            Contact Us
          </div>
        </Link>
        <div className="hidden md:flex items-center space-x-[25px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <span className="cursor-pointer text-[14px] leading-[16.71px] tracking-[-0.05em] font-semibold" onClick={() => navigate(homeRef)}>Home</span>
          <span className="cursor-pointer text-[14px] leading-[16.71px] tracking-[-0.05em]" onClick={() => navigate(aboutRef)}>About</span>
          <span className="cursor-pointer text-[14px] leading-[16.71px] tracking-[-0.05em]" onClick={() => navigate(serviceRef)}>Service</span>
          <span className="cursor-pointer text-[14px] leading-[16.71px] tracking-[-0.05em]" onClick={() => navigate(helpRef)}>Help</span>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
