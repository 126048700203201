import { createContext, useRef } from "react";

export const RefContext = createContext(null);

export default function RefContextProvider({ children }) {
  const headerRef = useRef(null);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const helpRef = useRef(null);
  const footerRef = useRef(null);
  return <RefContext.Provider value={{ headerRef, homeRef, aboutRef, serviceRef, helpRef, footerRef }}>{children}</RefContext.Provider>
};